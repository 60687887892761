<template>
  <div>
    <b-modal v-model="modalShow" title="Penanganan" hide-footer size="lg" centered>
      <validation-observer ref="penanganan" tag="form" @submit.prevent="submitAdd">
        <loading v-if="LoadingAdd"/>
        <b-form v-if="!LoadingAdd" @submit.prevent="submitAdd">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Jenis Penanganan">
                <validation-provider #default="{ errors }" name="Judul" rules="required">
                  <multiselect  v-model="form.type" :options="penangananType" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Jenis Penanganan" label="text" track-by="value"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Nama Penanganan">
                <validation-provider #default="{ errors }" name="Judul" rules="required">
                  <b-form-input v-model="form.name" placeholder="Judul"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Tabel -->
    <b-row>
      <b-col class="col-8">
        <b-form-group>
          <b-button @click="showModal" size="sm" variant="primary" v-if="validAddPenanganan">+ Penanganan Kasus</b-button>
        </b-form-group>
      </b-col>
      <b-col class="col-2 text-right">
        <b-form-group>
          <validation-provider #default="{ errors }" name="tanggal" rules="required">
            <flat-pickr @input="submitFilter" v-model="tanggal" :config="config" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" placeholder="Date Inserted" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col class="col-2 text-right">
        <b-form-group>
          <validation-provider #default="{ errors }" name="Name" rules="required">
            <multiselect @input="submitFilter" v-model="status" :options="listStatus" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Status" label="text" track-by="value" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <loading v-if="LoadingAdd"></loading>
        <b-table v-if="!LoadingAdd" striped hover :items="filteredTableData" :fields="fields" :per-page="perPage" :current-page="currentPage" show-empty>
          <template v-slot:cell(action)="row">
            <b-button @click="detail(row.item)" variant="primary" size="sm"><feather-icon icon="SettingsIcon" class="cursor-pointer"/></b-button>&nbsp;
            <b-button v-if="validDeletePenanganan" @click="deletePenanganan(row.item.id)" variant="danger" size="sm"><feather-icon icon="TrashIcon" class="cursor-pointer"/></b-button>&nbsp;
          </template>
          <template v-slot:cell(status_name)="row">
            <b-badge :variant="row.item.badge">{{row.item.status_name}}</b-badge>
          </template>
          <template #empty>
            <div v-if="!Loading" class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
            <loading v-if="Loading"/>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="filteredTableData.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from "vue-multiselect";
import { ModelSelect } from "vue-search-select";
import flatPickr from "vue-flatpickr-component";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    ModelSelect,
    flatPickr,
    axios
  },
  data() {
    return {
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      filter: null,
      filterOptions: [
        { text: 'Baru Dibuat', value: '0' },
        { text: 'Assign', value: '1' },
        { text: 'On Progress', value: '2' },
        { text: 'Sukses', value: '3' },
        { text: 'Failed', value: '4' },
        { text: 'Tarik Mundur', value: '5' },
        { text: 'Code 4', value: '6' },
      ],
      Loading: false,
      LoadingAdd: false,
      listPenanganan: false,
      fields: [
        {key:"name",text:"Judul Kasus"},
        {key:"created_by_name",text:"Penyidik"},
        {key:"formated_created_date",text:"Dibuat Tanggal"},
        {key:"status_name",text:"Status"},
        {key:"action",text:"Action"},
      ],
      modalShow: false,
      form: _interfaces.kasus_berjalan,
      tableData: [],
      penangananType: [],
      validAddPenanganan: [],
      validDeletePenanganan: false,
      listStatus: [],
      tanggal: null,
      status: null,
      config: {
        mode: "range",
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d M Y",
        allowInput: true,
      },
    };
  },
  computed: {
    filteredTableData() {
      if(this.filter == null){
        return this.tableData;
      }else{
        return this.tableData.filter(item => {
          return item.status.toLowerCase().includes(this.filter.toLowerCase())
        });
      }
    },
  },
  methods: {
    init(){
      this.checkValidAccessButton(8);
      this.checkValidDeleteButton(9);
      this.getType();
      this.getStatus();
      this.getData(this.tanggal,this.status);
    },
    deletePenanganan(id_penanganan){
      this.$swal({
        title: "Hapus Penanganan?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {  

          this.LoadingAdd = true;
          setTimeout(() => {
            axios.post("idp/penanganan/delete",{id:id_penanganan}).then((response) => {
              this.$swal({
                title: "Success",
                icon: "question",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }).catch((error) => {
              if (error.status === 4000) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
                ({
                  title: "Login Expired",
                  message: "Sesi Login Habis",
                });
              }
            }).finally(() => {
              this.LoadingAdd = false;
              this.modalShow = false;
              this.init();
            });
          }, 1000);

        } else {
          this.modalShow = false
        }
      });
    },
    checkValidAccessButton(id_config){
      axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
        this.validAddPenanganan = response.data.valid
      })
    },
    checkValidDeleteButton(id_config){
      axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
        this.validDeletePenanganan = response.data.valid
      })
    },
    showModal() {
      this.modalShow = true;
    },
    getData() {
      this.Loading = true;
      axios.post("idp/penanganan/list",{status:null,tanggal:null}).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    getType() {
      axios.post("idp/penanganan/type",{id:null}).then((response) => {
        this.penangananType = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.modalShow = false;
      });
    },
    getStatus() {
      axios.get("idp_dropdown/penanganan-status").then((response) => {
        this.listStatus = [{ text: 'All', value: 'all' },{ text: 'Baru Dibuat', value: '0' }, ...response.data.data];
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.modalShow = false;
      });
    },
    submitAdd() {
      this.$refs.penanganan.validate().then((success) => {
        if(success){ 


          this.$swal({
            title: "Tambahkan Penanganan?",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            if (result.isConfirmed) {  

              this.LoadingAdd = true;
              setTimeout(() => {
                axios.post("idp/penanganan/add",this.form).then((response) => {
                  
                }).catch((error) => {
                  if (error.status === 4000) {
                    localStorage.clear();
                    this.$router.push({ path: "/login" });
                    ({
                      title: "Login Expired",
                      message: "Sesi Login Habis",
                    });
                  }
                }).finally(() => {
                  this.LoadingAdd = false;
                  this.modalShow = false;
                  this.init();
                });
              }, 1000);

            } else {
              this.modalShow = false
            }
          });
        }
      });
    },
    submitFilter(){
      this.Loading = true;
      if(this.status.value != null && this.tanggal != null){
        axios.post("idp/penanganan/list",{status:this.status,tanggal:this.tanggal.split(" to ")}).then((response) => {
          this.tableData = response.data.data
        }).catch((error) => {
          if (error.status === 4000) {
            localStorage.clear();
            this.$router.push({ path: "/login" });
            ({
              title: "Login Expired",
              message: "Sesi Login Habis",
            });
          }
        }).finally(() => {
          this.Loading = false;
        });
      }
    },
    detail(row){
      this.$router.push({ path: "/idp-penanganan-detail/"+row.id });
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>