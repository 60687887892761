var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":"Penanganan","hide-footer":"","size":"lg","centered":""},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('validation-observer',{ref:"penanganan",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitAdd($event)}}},[(_vm.LoadingAdd)?_c('loading'):_vm._e(),(!_vm.LoadingAdd)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitAdd($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis Penanganan"}},[_c('validation-provider',{attrs:{"name":"Judul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.penangananType,"multiple":false,"close-on-select":true,"clear-on-select":true,"placeholder":"Pilih Jenis Penanganan","label":"text","track-by":"value"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1716553769)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Penanganan"}},[_c('validation-provider',{attrs:{"name":"Judul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Judul"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,90170331)})],1)],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Submit")])],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-8"},[_c('b-form-group',[(_vm.validAddPenanganan)?_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.showModal}},[_vm._v("+ Penanganan Kasus")]):_vm._e()],1)],1),_c('b-col',{staticClass:"col-2 text-right"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"config":_vm.config,"placeholder":"Date Inserted"},on:{"input":_vm.submitFilter},model:{value:(_vm.tanggal),callback:function ($$v) {_vm.tanggal=$$v},expression:"tanggal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-2 text-right"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.listStatus,"multiple":false,"close-on-select":true,"clear-on-select":true,"placeholder":"Pilih Status","label":"text","track-by":"value"},on:{"input":_vm.submitFilter},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-12"},[(_vm.LoadingAdd)?_c('loading'):_vm._e(),(!_vm.LoadingAdd)?_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.filteredTableData,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"show-empty":""},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.detail(row.item)}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"SettingsIcon"}})],1),_vm._v(" "),(_vm.validDeletePenanganan)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deletePenanganan(row.item.id)}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"}})],1):_vm._e()]}},{key:"cell(status_name)",fn:function(row){return [_c('b-badge',{attrs:{"variant":row.item.badge}},[_vm._v(_vm._s(row.item.status_name))])]}},{key:"empty",fn:function(){return [(!_vm.Loading)?_c('div',{staticClass:"text-center my-3"},[_vm._v(" Tidak ada data yang tersedia saat ini. ")]):_vm._e(),(_vm.Loading)?_c('loading'):_vm._e()]},proxy:true}],null,false,1029211912)}):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-pagination',{attrs:{"total-rows":_vm.filteredTableData.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }